import "./App.css";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import LoginScreen from "./screens/LoginScreen";
import NotFound from "./screens/NotFound";
import { ChakraProvider } from "@chakra-ui/react";
import Logout from "./screens/Logout";
import ListCases from "./screens/ListCases";
import ViewPatientForm from "./screens/ViewPatientForm";
import ViewPostSurgeryForm from "./screens/ViewPostSurgeryForm";
import ViewSAFEReviewForm from "./screens/ViewSAFEReviewForm";
import EditPatientForm from "./screens/EditPatientForm";
import EditPostSurgeryForm from "./screens/EditPostSurgeryForm";
import EditSafeReviewForm from "./screens/EditSafeReviewForm";

import NewProfile from "./screens/NewProfile";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <ChakraProvider>
        <HashRouter>
          <Switch>
            <Route exact path="/">
              <Redirect to="/login" />
            </Route>

            <Route path="/new_patient_form" component={NewProfile} />
            <Route path="/edit_patient_form/:id" component={EditPatientForm} />
            <Route
              path="/edit_post_surgery_form/:id"
              component={EditPostSurgeryForm}
            />
            <Route
              path="/edit_safe_review_form/:id"
              component={EditSafeReviewForm}
            />

            <Route path="/patient_form/:id" component={ViewPatientForm} />
            <Route
              path="/post_surgery_form/:id"
              component={ViewPostSurgeryForm}
            />
            <Route
              path="/safe_review_form/:id"
              component={ViewSAFEReviewForm}
            />
            <Route path="/list" component={ListCases} />
            <Route path="/login" component={LoginScreen} />
            <Route path="/logout" component={Logout} />
            <Route component={NotFound} />
          </Switch>
        </HashRouter>
      </ChakraProvider>
    </ErrorBoundary>
  );
}

export default App;
