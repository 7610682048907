const patientFormFields = [
  {
    id: "case",
    label: "SAFE Case Number",
    type: "Text",
  },
  // -- case info -- //
  {
    id: "patientName",
    label: "Patient Name*",
    type: "Text",
    required: true,
  },
  {
    id: "gender",
    label: "Gender*",
    type: "Select",
    options: [{ name: "Male" }, { name: "Female" }],
    required: true,
  },
  {
    id: "maritalStatus",
    label: "Marital Status*",
    type: "Select",
    options: [
      { name: "Married" },
      { name: "Widow/Widowed" },
      { name: "Divorced" },
      { name: "Single" },
    ],
    required: true,
  },
  {
    id: "dob",
    label: "Date Of Birth*",
    type: "date",
    required: true,
  },
  {
    id: "age",
    label: "Age",
    type: "number",
    required: true,
  },
  {
    id: "age-months",
    label: "Age in Months (if under 2 years)",
    type: "number",
  },
  {
    id: "patientPhone",
    label: "Patient Phone",
    type: "tel",
    required: true,
  },
  {
    id: "hospitalInpatientNo",
    label: "Hospital Inpatient Number",
    type: "Text",
  },
  {
    id: "patientDescriptor",
    label: "Patient Descriptor*",
    type: "Text",
    required: true,
  },
  {
    id: "nokName",
    label: "Next Of Kin Name",
    type: "Text",
    required: true,
  },
  {
    id: "nokRelationship",
    label: "Next Of Kin Relationship",
    type: "Select",
    options: [
      { name: "Parent" },
      { name: "Spouse" },
      { name: "Sibling" },
      { name: "Other" },
    ],
  },
  {
    id: "nokRelationshipOther",
    label: "Next Of Kin Relationship (if Other)",
    type: "Text",
  },
  {
    id: "nokPhone",
    label: "Next Of Kin Phone",
    type: "tel",
    required: true,
  },
  {
    id: "patientsVillage",
    label: "Patients Village",
    type: "Text",
  },
  {
    id: "patientsNearestTown",
    label: "Patients Nearest Town",
    type: "Text",
  },
  {
    id: "patientCounty",
    label: "County",
    type: "Select",
    options: [
      { name: "Baringo" },
      { name: "Bomet" },
      { name: "Bungoma" },
      { name: "Busia" },
      { name: "Elgeyo Marakwet" },
      { name: "Embu" },
      { name: "Garissa" },
      { name: "Homa Bay" },
      { name: "Isiolo" },
      { name: "Kajiado" },
      { name: "Kakamega" },
      { name: "Kericho" },
      { name: "Kiambu" },
      { name: "Kilifi" },
      { name: "Kirinyaga" },
      { name: "Kisii" },
      { name: "Kisumu" },
      { name: "Kitui" },
      { name: "Kwale" },
      { name: "Laikipia" },
      { name: "Lamu" },
      { name: "Machakos" },
      { name: "Makueni" },
      { name: "Mandera" },
      { name: "Marsabit" },
      { name: "Meru" },
      { name: "Migori" },
      { name: "Mombasa" },
      { name: "Murang'a" },
      { name: "Nairobi" },
      { name: "Nakuru" },
      { name: "Nandi" },
      { name: "Narok" },
      { name: "Nyamira" },
      { name: "Nyandarua" },
      { name: "Nyeri" },
      { name: "Samburu" },
      { name: "Siaya" },
      { name: "Taita Taveta" },
      { name: "Tana River" },
      { name: "Tharaka-Nithi" },
      { name: "Trans Nzoia" },
      { name: "Turkana" },
      { name: "Uasin Gishu" },
      { name: "Vihiga" },
      { name: "Wajir" },
      { name: "West Pokot" },
    ],
  },
  //--- treatment info ---//
  {
    id: "medicalCondition",
    label: "Medical Condition*",
    type: "Text",
    required: true,
  },
  {
    id: "surgeryType",
    label: "Surgery Type*",
    type: "Select",
    options: [{ name: "Elective" }, { name: "Emergency" }],
    required: true,
  },
  {
    id: "surgeryCategory",
    label: "Surgery Category*",
    type: "Select",
    options: [
      { name: "ENT" },
      { name: "Plastics and reconstructive" },
      { name: "Orthopaedics" },
      { name: "OB-GYN" },
      { name: "General surgery" },
      { name: "Neurosurgery" },
      { name: "Paediatrics/ Congenital surgery" },
      { name: "Ophthalmology " },
      { name: "Other" },
    ],
    required: true,
  },
  {
    id: "surgeryCategoryOther",
    label: "Surgery Category (Other)",
    type: "Text",
  },
  {
    id: "requiredTreatmentGenerated",
    label: "Required Treatment*",
    type: "hospitalProcedure",
    required: true,
    //hospital: DataService.shared.getHospital(),
  },
  {
    id: "requiredTreatment",
    label: "Required Treatment (Other)",
    type: "Text",
  },
  {
    id: "fundingAsk",
    label: "Funding Ask (USD$)*",
    type: "number",
    required: true,
  },
  {
    id: "copay",
    label: "Co-Pay (USD$)*",
    type: "number",
    required: true,
  },
  {
    id: "impactOfIllness",
    label: "Impact Of Illness*",
    type: "Textarea",
    required: true,
  },
  {
    id: "privacyRequest",
    label: "Privacy request* (Yes/No)",
    type: "yesno",
    required: true,
  },
  {
    id: "privacyRequestReason",
    label: "Reason for Privacy Request",
    type: "Textarea",
  },
  {
    id: "expectedTreatmentDate",
    label: "Expected Treatment Date*",
    type: "date",
  },
  //--- personal --//
  {
    id: "patientStory",
    label: "Patient Story*",
    type: "Textarea",
    required: true,
  },
  {
    id: "personalQuote",
    label: "Personal Quote*",
    type: "Textarea",
    required: true,
  },
  {
    id: "patientPhotos",
    label: "Patient Photo",
    type: "Photo",
    //required: true,
  },
  {
    id: "patientConsent",
    label: "Patient Consent Form",
    type: "Photo",
    //required: true,
  },
];

export default patientFormFields;
