import React from "react";
import { Image, Button, Box, Center, Text } from "@chakra-ui/react";
import logo from "../assets/amh-logo.svg";
import { Redirect } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }
  render() {
    const goToList = () => {
      window.location = "#/list";
    };
    if (this.state.hasError) {
      return <Redirect to="/list" />;
      // You can render any custom fallback UI
      // return (
      //   <Center>
      //     <Box>
      //       <Image src={logo} style={{ width: "300px" }} />

      //       <Center>
      //         <Text fontSize={6}>Something went wrong :(</Text>
      //       </Center>

      //       <Button title="Login" mt={"10px"} onClick={goToList}>
      //         Home
      //       </Button>
      //     </Box>
      //   </Center>
      // );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
