import { Text, Center, Image } from "@chakra-ui/react";
import logo from "../assets/amh-logo.svg";
import Header from "../components/header";

const NotFound = () => {
  return (
    <>
      <Header />
      <Center>
        <Text fontSize="3xl">Error: Route not found</Text>
      </Center>
      <br />
      <br />
      <Center>
        <Image src={logo} w={"50%"} />
      </Center>
    </>
  );
};

export default NotFound;
