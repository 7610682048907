import { useEffect, useState } from "react";
import {
  approvePostSurgeryFormCase,
  flagPatientFormCase,
  getCase,
  isAdmin,
} from "../services/data/data";
import { Box, Button, Text, Center } from "@chakra-ui/react";
import Loader from "../components/loader";
import postSurgeryFormFields from "../services/data/postSurgeryFormData";
import Header from "../components/header";
import FormPiece from "../components/formpiece";
import {
  sendPostSurgeyFormApproval,
  sendPostSurgeyFormFlagged,
} from "../services/email";

const ViewPostSurgeryForm = ({ match, location }) => {
  const caseId = match.params.id;

  const [caseData, setCaseData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let data = await getCase(caseId);
      setCaseData(data);
    }
    fetchData();
  }, [caseId]);

  if (!caseData) {
    return <Loader />;
  }

  const editForm = () => {
    window.location = `#/edit_post_surgery_form/${caseData["id"]}`;
  };

  const approveCase = () => {
    let answer = window.confirm("Approve this case?");
    if (answer) {
      approvePostSurgeryFormCase(caseData["id"]);
      const email = sendPostSurgeyFormApproval(caseData);

      window.open(email);
      window.location = `#/list`;
    }
  };

  const flagCase = () => {
    let answer = prompt("What is the reason for flagging this case?");

    if (typeof answer == "string" && answer.trim() === "") {
      alert("A reason is needed for flagging a case.");
    }

    if (answer) {
      flagPatientFormCase(answer, caseData["id"]);

      const email = sendPostSurgeyFormFlagged(caseData);

      window.open(email);
      window.location = `#/list`;
    }
  };

  const OtherFormButtons = () => {
    let isApproved = caseData["postSurgeryFormStatus"] === "Approved";
    let userIsAdmin = isAdmin();

    return (
      <Box width="120px" mx="20px">
        {!isApproved ? (
          <Button mb={1} background="yellow.300" onClick={editForm}>
            Edit Form
          </Button>
        ) : null}

        {userIsAdmin ? (
          <>
            <Button mb={1} background="green.100" onClick={approveCase}>
              Approve Case
            </Button>
            <Button mb={1} background="red.100" onClick={flagCase}>
              Flag Case
            </Button>
          </>
        ) : null}
      </Box>
    );
  };

  return (
    <>
      <Header />
      <Box my={3} mx={"auto"} maxW="960px" display="flex">
        <Box width="800px">
          <Center>
            <Text fontSize="4xl">Post Surgery Form</Text>
          </Center>
          <Center>
            <Text>Status: {caseData["postSurgeryFormStatus"]}</Text>
          </Center>
          {caseData["rejectReason"] ? (
            <Center>
              <Box outline={"1px solid"} outlineColor={"red"} px={3}>
                <Text color="red">Reason: {caseData["rejectReason"]}</Text>
              </Box>
            </Center>
          ) : null}

          {postSurgeryFormFields.map((f) => (
            <FormPiece key={Math.random()} {...f} value={caseData[f.id]} />
          ))}
        </Box>
        <OtherFormButtons />
      </Box>
    </>
  );
};

export default ViewPostSurgeryForm;
