import { useState } from "react";
import { login } from "../services/data/data";
import logo from "../assets/amh-logo.svg";
import { Redirect } from "react-router-dom";
import { Input, Image, Button, Box, Center, Spinner } from "@chakra-ui/react";

const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    await login(
      email,
      pass,
      (err) => setError(err),
      () => setLoggedIn(true),
      () => setLoading(false)
    );
  };

  return loggedIn ? (
    <Redirect to="/list" />
  ) : (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "500px",
          alignItems: "center",
          margin: "100px auto",
        }}
      >
        {loading ? (
          <Center h="200px">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Center>
        ) : (
          <Box>
            <Image src={logo} style={{ width: "500px" }} />
            {error ? (
              <h3 style={{ color: "red" }}>{error.toString()}</h3>
            ) : null}
            <Input
              mt={"10px"}
              type="text"
              placeholder="Email"
              onChange={(email) => setEmail(email.target.value)}
              value={email}
            />
            <Input
              mt={"10px"}
              type="password"
              placeholder="Password"
              onChange={(password) => setPass(password.target.value)}
              value={pass}
              onSubmit={handleLogin}
            />
            <Button title="Login" mt={"10px"} onClick={handleLogin}>
              Log In
            </Button>
          </Box>
        )}
      </div>
    </>
  );
};

export default LoginScreen;
