import { getEmail } from "../data/data";
import lookupKey from "../lookupKey";
// import { sendEmail } from "../sendgrid/sendgrid";

export default async function sendEmail(to, subject, body) {
  let url = `mailto:${to}?subject=${subject}&body=${body}`;

  return url;
}

export async function patientEmail(data) {
  return await sendEmail(
    "sponsor@africanmissionhealthcare.org",
    `SAFE Patient Form Submitted: ${data.patientName}`,
    `SAFE Patient Form submitted for patient: '${
      data.patientName
    }' by ${await getEmail()}.
    Please review this form at your earliest convience:

    Patient Data: 
    ${printKeys(data)}
    `
  );
}

export async function sendPatientFormApproval(caseObject) {
  return await sendEmail(
    caseObject.createdBy,
    `SAFE Patient Form Approved: ${caseObject.patientName}`,
    `SAFE Patient Form Approved for patient: '${caseObject.patientName}'. 
  Please fill out the Post Surgery Form after the surgery occurs.`
  );
}

export async function sendPatientFormFlagged(caseObject) {
  return await sendEmail(
    caseObject.createdBy,
    `SAFE Patient Form Flagged: ${caseObject.patientName}`,
    `SAFE Patient Form Flagged for patient: '${caseObject.patientName}'. 
     Reason: ${caseObject.rejectReason} 
     Please review this form and fix any errors and resubmit.`
  );
}

export async function sendPostSurgeyFormApproval(caseObject) {
  return await sendEmail(
    caseObject.createdBy,
    `SAFE Post Surgery Form Approved: ${caseObject.patientName}`,
    `SAFE Post Surgery Approved for patient: '${caseObject.patientName}'.`
  );
}

export async function sendPostSurgeyFormFlagged(caseObject) {
  return await sendEmail(
    caseObject.createdBy,
    `SAFE Post Surgery Form Flagged: ${caseObject.patientName}`,
    `SAFE Post Surgery Flagged for patient: '${caseObject.patientName}'.
    Reason: [Please fill in reason]`
  );
}

export async function sendPostSurgeryFormSubmitted(savedData) {
  return await sendEmail(
    "sponsor@africanmissionhealthcare.org",
    `SAFE Post Surgery Form Submitted: ${savedData.patientName}`,
    `SAFE Post Surgery Form submitted for patient: '${
      savedData.patientName
    }' by ${getEmail()}. 
    Please review this form at your earliest convience:

    Patient Data: 
    ${Object.keys(savedData).map((key) => {
      if (
        [
          "patientFormApprovedDate",
          "patientFormSubmitDate",
          "postSurgeryFormApprovedDate",
          "postSurgeryFormSubmitDate",
          "timestamp",
        ].includes(key)
      ) {
        return `
        ${lookupKey(key)}: ${new Date(savedData[key]).toDateString()}`;
      }
      if (
        [
          "patientConsent",
          "postOpPhotos",
          "surgicalScalePhoto",
          "patientPhotos",
        ].includes(key)
      ) {
        if (typeof (savedData[key] === "Array")) {
          const urls = savedData[key].map((i) => encodeURI(i)).join(" ");
          return "";
        }
      }
      return `
      ${lookupKey(key)}: ${savedData[key]}`;
    })}
    `
  );
}

export async function sendSAFEReviewFlagged(caseObject) {
  return await sendEmail(
    caseObject.createdBy,
    `SAFE Review Flagged: ${caseObject.patientName}`,
    `SAFE Review Flagged for case: '${caseObject.patientName}'.
     
    For reason: ${caseObject.safeReviewRejectReason}.
    
    Please review.
    `
  );
}

const printKeys = (data) => {
  return `${Object.keys(data).map((key) => {
    if (
      [
        "patientFormApprovedDate",
        "patientFormSubmitDate",
        "postSurgeryFormApprovedDate",
        "postSurgeryFormSubmitDate",
        "timestamp",
      ].includes(key)
    ) {
      return `
      ${lookupKey(key)}: ${new Date(data[key]).toDateString()}`;
    }

    if (
      [
        "patientConsent",
        "postOpPhotos",
        "surgicalScalePhoto",
        "patientPhotos",
      ].includes(key)
    ) {
      if (typeof (data[key] === "Array")) {
        const urls = data[key].map((i) => encodeURI(i)).join(" ");
        return "";
      }
    }
    return `${lookupKey(key)}: ${data[key]}`;
  })}
  `;
};
