import {
  Input,
  FormControl,
  Image,
  Textarea,
  FormLabel,
  Select,
} from "@chakra-ui/react";

const FormPiece = ({ id, label, type, value, options, required }) => {
  const InputSwitcher = () => {
    switch (type) {
      case "Select":
        return (
          <Select value={value} readOnly={true}>
            <option value={value}>{value}</option>
          </Select>
        );
      case "yesno":
        return (
          <Select value={value} readOnly={true}>
            <option value={value}>{value}</option>
          </Select>
        );
      case "Textarea":
        return <Textarea readOnly={true} rows={5} value={value} />;
      case "Photo":
        if (value == undefined) {
          return null;
        }
        return (
          <>
            {value.map((p) => (
              <Image src={p} />
            ))}
          </>
        );
      default:
        return <Input readOnly={true} type={type} value={value} />;
    }
  };

  return (
    <FormControl id={id}>
      <FormLabel>{label}</FormLabel>
      <InputSwitcher />
    </FormControl>
  );
};

export default FormPiece;
