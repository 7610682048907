import { useEffect, useState } from "react";
import { getList, getUser } from "../services/data/data";
import { Redirect } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Flex,
  Tr,
  Th,
  Td,
  Center,
  IconButton,
  Button,
  Link,
  Text,
  Input,
  HStack,
  FormControl,
} from "@chakra-ui/react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Search2Icon } from "@chakra-ui/icons";
import Loader from "../components/loader";
import Header from "../components/header";
import { Field, Form, Formik } from "formik";

const ListCases = () => {
  let user = getUser();

  const [cases, setCases] = useState(null);
  const [months, setMonths] = useState(1);
  const [nameToSearch, setNameToSearch] = useState("");

  useEffect(() => {
    if (user) {
      setCases(null);
      async function fetchData() {
        let data = await getList(months * 30, true, { name: nameToSearch });
        setCases(data);
      }
      fetchData();
    }
  }, [user, months, nameToSearch]);

  const loadMore = () => {
    setMonths(months + 1);
  };

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (cases) {
    return (
      <>
        <Header />
        <div>
          <Center>
            <Formik
              initialValues={{ name: nameToSearch }}
              onSubmit={(values, actions) => {
                setNameToSearch(values.name);
              }}
            >
              {(props) => (
                <Form>
                  <HStack maxW="400" mb="5">
                    <Field name="name">
                      {({ field, form }) => (
                        <FormControl>
                          <Input
                            {...field}
                            id="name"
                            placeholder="Enter Name"
                          />
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      mt={4}
                      colorScheme="blue"
                      isLoading={props.isSubmitting}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </HStack>
                </Form>
              )}
            </Formik>
          </Center>
          <hr />
          <TableComponent cases={cases} />
          <br />
          <Center>
            <Text fontSize={"12px"}>
              Showing data from the last {months * 30} days
            </Text>
          </Center>
          <Center>
            <Button onClick={loadMore}>Load More</Button>
          </Center>
        </div>
      </>
    );
  }
  return <Loader />;
};

const PatientFormCell = ({ data }) => {
  const formAvailable = !!data.patientFormStatus;

  return (
    <>
      <Flex alignItems={"center"} justifyContent="space-between">
        {data.patientFormStatus}
        {formAvailable ? (
          <IconButton
            ml={2}
            as={Link}
            colorScheme="blue"
            aria-label="View Patient Form"
            href={`#/patient_form/${data.id}`}
            icon={<Search2Icon />}
          />
        ) : (
          <Text color="gray.400">Not Available</Text>
        )}
      </Flex>
    </>
  );
};

const PostSurgeryFormCell = ({ data }) => {
  const formAvailable =
    !!data.postSurgeryFormStatus || data.patientFormStatus === "Approved";
  return (
    <>
      <Flex alignItems={"center"} justifyContent="space-between">
        {data.postSurgeryFormStatus}
        {formAvailable ? (
          <IconButton
            as={Link}
            ml={2}
            colorScheme="blue"
            aria-label="View PostSurgery Form"
            href={`#/post_surgery_form/${data.id}`}
            icon={<Search2Icon />}
          />
        ) : (
          <Text color="gray.400">Not Available</Text>
        )}
      </Flex>
    </>
  );
};

const SAFEReviewFormCell = (data) => {
  const formAvailable =
    !!data.saferewviewFormStatus || data.postSurgeryFormStatus === "Approved";
  return (
    <>
      <Flex alignItems={"center"} justifyContent="space-between">
        {data.saferewviewFormStatus}
        {formAvailable ? (
          <IconButton
            as={Link}
            ml={2}
            colorScheme="blue"
            aria-label="View SAFE REview Form"
            href={`#/safe_review_form/${data.id}`}
            icon={<Search2Icon />}
          />
        ) : (
          <Text color="gray.400">Not Available</Text>
        )}
      </Flex>
    </>
  );
};

const TableComponent = ({ cases }) => {
  const columns = [
    {
      accessorKey: "name",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "hospital",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "profileForm",
      cell: (info) => <PatientFormCell data={info.getValue()} />,
    },
    {
      accessorKey: "feedbackForm",
      cell: (info) => <PostSurgeryFormCell data={info.getValue()} />,
    },
    {
      accessorKey: "qualityAssuranceForm",
      cell: (info) => <SAFEReviewFormCell data={info.getValue()} />,
    },
    {
      accessorKey: "lastAction",
      cell: (info) => new Date(info.getValue()).toLocaleString(),
    },
  ];

  const data = cases.map((c) => ({
    name: c.patientName,
    hospital: c.hospital,
    profileForm: c,
    feedbackForm: c,
    qualityAssuranceForm: c,
    lastAction: c.timestamp,
  }));

  const [sorting, setSorting] = useState([]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table variant="striped">
      <Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Th key={header.id}>
                <div
                  {...{
                    onClick: header.column.getToggleSortingHandler(),
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  {{
                    asc: " 🔼",
                    desc: " 🔽",
                  }[header.column.getIsSorted()] ?? null}
                </div>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody>
        {table.getRowModel().rows.map((row) => (
          <Tr key={row.id}>
            {row.getVisibleCells().map(
              (cell) => {
                console.log("cell.id", cell.id);
                return (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              }
              // cell ? (

              // ) : null
            )}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default ListCases;
