const lookupKey = (key) => (dataTranslate[key] ? dataTranslate[key] : key);

const dataTranslate = {
  SAFEID: "SAFEID",
  actualTreatmentDate: "Actual Treatment Date",
  age: "Age",
  "age-months": "Age in months",
  copay: "Copay",
  createdBy: "Created By",
  dob: "Date Of Birth",
  expectedTreatmentDate: "Expected Treatment Date",
  feedbackQuote: "Feedback Quote",
  fundingAsk: "Funding Ask",
  gender: "Gender",
  hospital: "Hospital",
  hospitalInpatientNo: "Hospital Inpatient Number",
  id: "ID",
  impactOfIllness: "Impact Of Illness",
  key: "Key",
  maritalStatus: "Marital Status",
  medicalCondition: "Medical Condition",
  nokName: "Next Of Kin Name",
  nokPhone: "Next Of Kin Phone",
  nokRelationship: "Next Of Kin Relationship",
  patientConsent: "Patient has Given Consent",
  patientConsentExifDates: "Patient Consent EXIF Dates",
  patientCounty: "Patient County",
  patientDescriptor: "Patient Descriptor",
  patientFormApprovedDate: "Patient Form Approved Date",
  patientFormStatus: "Patient Form Status",
  patientFormSubmitDate: "Patient Form Submit Date",
  patientName: "Patient Name",
  patientPhone: "Patient Phone",
  patientPhotos: "Patient Photos",
  patientPhotosExifDates: "Patient Photos EXIF Dates",
  patientStory: "Patient Story",
  patientsNearestTown: "Patients Nearest Town",
  patientsVillage: "Patients Village",
  personalQuote: "Personal Quote",
  postOpPhotos: "Post Op Photos",
  postOpPhotosExifDates: "Post Op Photos EXIF Dates",
  postSurgeryFormStatus: "Post Surgery Form Status",
  postSurgeryFormApprovedDate: "Post Surgery Form Approved Date",
  postSurgeryFormSubmitDate: "Post Surgery Form Submit Date",
  privacyRequest: "Privacy Request",
  "qa-checkedBy": "QA Checked By",
  "qa-dateChecked": "QA Date Checked",
  "qa-updatePassed": "QA Update Passed",
  "qa-profileImpactOfIllnessPresent": "QA Profile Impact Of Illness Present",
  "qa-profilePatientPersonalDescription":
    "QA Profile Patient Personal Description",
  "qa-profilePersonalQuoteAcceptable": "QA Profile Personal Quote Acceptable",
  "qa-waiverPatientNameVerify": "QA Wavier Patient Name Verify",
  "qa-waiverPostOpPhotoIsAfterSurgery":
    "QA Waiver Post Op Photo is After Surgery",
  "qa-waiverSignatureDate": "QA Waiver Signature Date",
  "qa-waiverSignaturePresent": "QA Waiver Signature Present",
  "qa-feedbackPersonalQuoteAcceptable": "QA Feedback Personal Quote Accepted",
  safeScoreNotCapturedReason: "SAFE Score Not Captured Reason",
  safeReviewApprovedDate: "SAFE Review Approved Date",
  safeReviewApprovedBy: "SAFE Review Approved By",
  requiredTreatment: "Required Treatment",
  requiredTreatmentGenerated: "Required Treatment",
  safeReviewFormStatus: "SAFE Review Form Status",
  safeScore1: "SAFE Score 1",
  specificTreatmentRecieved: "Specific Treatment Recieved",
  status: "Status",
  surgeryType: "Surgery Type",
  surgicalScalePhoto: "Surgical Scale Photo",
  surgicalScalePhotoExifDates: "Surgical Scale Photo EXIF Dates",
  timestamp: "Timestamp",
  treatmentOutcome: "Treatment Outcome",
  treatmentOutcomeOther: "Treatment Outcome",
  updatedBy: "Updated By",
  feedbackApproved: "Feedback Approved",
};

export default lookupKey;
