import { getUser, saveCase, uploadPhotos } from "../services/data/data";
import {
  Box,
  Text,
  Center,
  Button,
  Input,
  FormControl,
  Image,
  Textarea,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { Redirect } from "react-router-dom";
import patientFormFields from "../services/data/patientFormData";
import Header from "../components/header";

import { useFormik } from "formik";
import { useState } from "react";

const NewProfile = ({ match, location }) => {
  let user = getUser();

  if (!user) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Header />
      <PatientForm data={{}} />
    </>
  );
};

const PatientForm = ({ data }) => {
  const [submitFormVal, setSubmitFormVal] = useState(false);
  const [photoUrls, setPhotoUrls] = useState([]);

  const inputPhotoUrlUpdate = (id, urls) => {
    setPhotoUrls({
      ...photoUrls,
      [`${id}`]: urls,
    });
  };

  const formik = useFormik({
    initialValues: data,
    validate: (values) => {
      // const errors = {};
      // patientFormFields.forEach((field) => {
      //   if (field.required && !values[field.id]) {
      //     errors[field.id] = "Required";
      //   }
      // });
      // return errors;
    },
    onSubmit: async (values) => {
      let valuesToAdd = {};
      if (submitFormVal) {
        valuesToAdd.patientFormStatus = "Submitted";
      }

      Object.keys(photoUrls).map((key) => {
        valuesToAdd[`${key}`] = photoUrls[`${key}`];
        return 1;
      });

      await saveCase({ ...values, ...valuesToAdd });

      window.location = "#/list";
    },
  });

  const saveForm = () => {
    let saveConfirm = window.confirm("Save this form?");

    if (saveConfirm) {
      formik.handleSubmit();
    }
  };

  const submitForm = () => {
    let saveConfirm = window.confirm("Save and submit this form?");

    if (saveConfirm) {
      setSubmitFormVal(true);
      formik.handleSubmit();
    }
  };

  const resetForm = () => {
    let resetConfirm = window.confirm("Reset this form?");

    if (resetConfirm) {
      formik.resetForm();
    }
  };

  return (
    <Box my={3} mx={"auto"} maxW="960px" display="flex">
      <Box width="800px">
        <Center>
          <Text fontSize="4xl">New Patient Form</Text>
        </Center>
        <form onSubmit={formik.handleSubmit}>
          {patientFormFields.map((f, idx) => (
            <FormControl key={idx} id={f.id}>
              <FormLabel>{f.label} </FormLabel>

              <InputSwitcher
                {...f}
                value={formik.values[f.id]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched[f.id]}
                error={!!formik.errors[f.id]}
                setPhotoUrls={inputPhotoUrlUpdate}
              />
              {formik.errors[f.id] ? (
                <>
                  <Text color="red.400" fontSize="12px" fontWeight="bold">
                    {formik.errors[f.id]}
                  </Text>
                  &nbsp;
                </>
              ) : null}
            </FormControl>
          ))}

          <Button onClick={saveForm} background="green.100">
            Save Changes
          </Button>
        </form>
      </Box>
      <Box width="120px" mx="20px">
        <Button mb={1} background="green.100" onClick={saveForm}>
          Save Changes
        </Button>
        <Button mb={1} background="green.100" onClick={submitForm}>
          Save and Submit
        </Button>
        <Button mb={1} background="red.100" onClick={resetForm}>
          Reset Form
        </Button>
      </Box>
    </Box>
  );
};

const InputSwitcher = ({
  id,
  type,
  value,
  onChange,
  onBlur,
  options,
  touched,
  error,
  setPhotoUrls,
}) => {
  const borderColor = error ? "red.400" : touched ? "green.400" : null;
  const border = error || touched ? "2px" : null;

  const uploadPhotoAndPost = async (e) => {
    const urls = await uploadPhotos(e.target.files);

    setPhotoUrls(id, urls);
  };
  switch (type) {
    case "Select":
      return (
        <Select
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          border={border}
          borderColor={borderColor}
        >
          {options.map((o, idx) => (
            <option key={idx} value={o.name}>
              {o.name}
            </option>
          ))}
        </Select>
      );
    case "yesno":
      return (
        <Select
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          border={border}
          borderColor={borderColor}
        >
          <option value="" disabled selected hidden>
            Please Select
          </option>
          <option value={"Yes"}>Yes</option>
          <option value={"No"}>No</option>
        </Select>
      );
    case "Textarea":
      return (
        <Textarea
          onChange={onChange}
          onBlur={onBlur}
          rows={5}
          value={value}
          border={border}
          borderColor={borderColor}
        />
      );
    case "Photo":
      return (
        <>
          {value &&
            value.map((p, idx) => <Image key={idx} src={p} maxW={"200px"} />)}
          <Input
            type="file"
            multiple={true}
            id="fileInput"
            onChange={uploadPhotoAndPost}
          />
        </>
      );
    default:
      return (
        <Input
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          value={value}
          border={border}
          borderColor={borderColor}
        />
      );
  }
};

export default NewProfile;
