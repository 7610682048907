import { useEffect, useState } from "react";
import { getCase, getUser, saveCase } from "../services/data/data";
import {
  Box,
  Text,
  Center,
  Divider,
  Button,
  Input,
  FormControl,
  Image,
  Textarea,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import Loader from "../components/loader";
import { Redirect } from "react-router-dom";
import {
  waiverFields,
  profileFields,
  feedbackFields,
  qaFields,
} from "../services/data/safeReviewFormData";
import Header from "../components/header";

import { useFormik } from "formik";

const EditSafeReviewForm = ({ match, location }) => {
  let user = getUser();
  const caseId = match.params.id;

  const [caseData, setCaseData] = useState(null);

  useEffect(() => {
    if (user) {
      async function fetchData() {
        let data = await getCase(caseId);
        setCaseData(data);
      }
      fetchData();
    }
  }, [caseId, user]);

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (!caseData) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <PatientForm data={caseData} />
    </>
  );
};

const PatientForm = ({ data }) => {
  const [submitFormVal, setSubmitFormVal] = useState(false);

  const formik = useFormik({
    initialValues: data,
    onSubmit: async (values) => {
      if (submitFormVal) {
        await saveCase({
          ...values,
          safeReviewFormStatus: "Submitted",
        });
      } else {
        await saveCase(values);
      }

      window.location = "#/list";
    },
  });

  const saveForm = () => {
    let saveConfirm = window.confirm("Save this form?");

    if (saveConfirm) {
      formik.handleSubmit();
    }
  };

  const submitForm = () => {
    let saveConfirm = window.confirm("Save and submit this form?");

    if (saveConfirm) {
      setSubmitFormVal(true);
      formik.handleSubmit();
    }
  };

  const resetForm = () => {
    // eslint-disable-next-line no-restricted-globals
    let resetConfirm = confirm("Reset this form?");

    if (resetConfirm) {
      formik.resetForm();
    }
  };

  return (
    <Box my={3} mx={"auto"} maxW="960px" display="flex">
      <Box width="800px">
        <Center>
          <Text fontSize="4xl">Edit Safe Review Form</Text>
        </Center>
        <Center>
          <Text>Status: {data["safeReviewFormStatus"]}</Text>
        </Center>
        {data["rejectReason"] ? (
          <Center>
            <Box outline={"1px solid"} outlineColor={"red"} px={3}>
              <Text color="red">Reason: {data["rejectReason"]}</Text>
            </Box>
          </Center>
        ) : null}
        <form onSubmit={formik.handleSubmit}>
          <Center>
            <Text fontSize="2xl">Waiver Fields</Text>
          </Center>
          {waiverFields.map((f, idx) => (
            <FormControl key={idx} id={f.id}>
              <FormLabel>{f.label} </FormLabel>

              <InputSwitcher
                {...f}
                value={formik.values[f.id]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched[f.id]}
                error={!!formik.errors[f.id]}
              />
              {formik.errors[f.id] ? (
                <>
                  <Text color="red.400" fontSize="12px" fontWeight="bold">
                    {formik.errors[f.id]}
                  </Text>
                  &nbsp;
                </>
              ) : null}
            </FormControl>
          ))}
          <Divider />
          <Center>
            <Text fontSize="2xl">Profile Fields</Text>
          </Center>
          {profileFields.map((f, idx) => (
            <FormControl key={idx} id={f.id}>
              <FormLabel>{f.label} </FormLabel>

              <InputSwitcher
                {...f}
                value={formik.values[f.id]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched[f.id]}
                error={!!formik.errors[f.id]}
              />
              {formik.errors[f.id] ? (
                <>
                  <Text color="red.400" fontSize="12px" fontWeight="bold">
                    {formik.errors[f.id]}
                  </Text>
                  &nbsp;
                </>
              ) : null}
            </FormControl>
          ))}

          <Divider />
          <Center>
            <Text fontSize="2xl">Feedback Fields</Text>
          </Center>

          {feedbackFields.map((f, idx) => (
            <FormControl key={idx} id={f.id}>
              <FormLabel>{f.label} </FormLabel>

              <InputSwitcher
                {...f}
                value={formik.values[f.id]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched[f.id]}
                error={!!formik.errors[f.id]}
              />
              {formik.errors[f.id] ? (
                <>
                  <Text color="red.400" fontSize="12px" fontWeight="bold">
                    {formik.errors[f.id]}
                  </Text>
                  &nbsp;
                </>
              ) : null}
            </FormControl>
          ))}
          <Divider />
          <Center>
            <Text fontSize="2xl">QA Fields</Text>
          </Center>

          {qaFields.map((f, idx) => (
            <FormControl key={idx} id={f.id}>
              <FormLabel>{f.label} </FormLabel>

              <InputSwitcher
                {...f}
                value={formik.values[f.id]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                touched={formik.touched[f.id]}
                error={!!formik.errors[f.id]}
              />
              {formik.errors[f.id] ? (
                <>
                  <Text color="red.400" fontSize="12px" fontWeight="bold">
                    {formik.errors[f.id]}
                  </Text>
                  &nbsp;
                </>
              ) : null}
            </FormControl>
          ))}
          <Divider />

          <Button onClick={saveForm} background="green.100">
            Save Changes
          </Button>
        </form>
      </Box>
      <Box width="120px" mx="20px">
        <Button mb={1} background="green.100" onClick={saveForm}>
          Save Changes
        </Button>
        <Button mb={1} background="green.100" onClick={submitForm}>
          Save and Submit
        </Button>
        <Button mb={1} background="red.100" onClick={resetForm}>
          Reset Form
        </Button>
      </Box>
    </Box>
  );
};

const InputSwitcher = ({
  type,
  value,
  onChange,
  onBlur,
  options,
  touched,
  error,
}) => {
  const borderColor = error ? "red.400" : touched ? "green.400" : null;
  const border = error || touched ? "2px" : null;

  switch (type) {
    case "Select":
      return (
        <Select
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          border={border}
          borderColor={borderColor}
        >
          {options.map((o, idx) => (
            <option key={idx} value={o.name}>
              {o.name}
            </option>
          ))}
        </Select>
      );
    case "Textarea":
      return (
        <Textarea
          border={border}
          borderColor={borderColor}
          onChange={onChange}
          onBlur={onBlur}
          rows={5}
          value={value}
        />
      );
    case "Photo":
      return (
        <>
          <Image src={value} maxH={"200px"} />
          <Input type="file" id="fileInput" />
        </>
      );
    default:
      return (
        <Input
          border={border}
          borderColor={borderColor}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          value={value}
        />
      );
  }
};

export default EditSafeReviewForm;
