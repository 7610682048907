const postSurgeryFormFields = [
  {
    id: "SAFEID",
    label: "SAFE ID",
    type: "Text",
    locked: true,
  },
  {
    id: "patientName",
    label: "Patient Name",
    type: "Text",
    locked: true,
  },
  {
    id: "patientPhotos",
    label: "Patient Photos (Pre-Op)",
    type: "Photo",
    locked: true,
  },
  {
    id: "actualTreatmentDate",
    label: "Actual Treatment Date",
    type: "date",
  },
  {
    id: "specificTreatmentRecieved",
    label: "Specific Treatment Recieved",
    type: "Text",
  },
  {
    id: "safeScore1",
    label: "SAFE Score",
    type: "Select",
    options: [
      { name: "1" },
      { name: "2" },
      { name: "3" },
      { name: "4" },
      { name: "5" },
      { name: "Not Captured" },
    ],
  },
  {
    id: "safeScoreNotCapturedReason",
    label: "Reason Not Captured (if not)",
    type: "Text",
  },
  {
    id: "treatmentOutcome",
    label: "Treatment Outcome",
    type: "Select",
    options: [
      { name: "Success - Patient progressing as planned" },
      {
        name: "Success - Patient passed away after procedure due to unrelated causes",
      },
      { name: "Success - Patient lost to follow up" },
      { name: "Success - Additional treatment may be necessary" },
      { name: "Success - Original treatment plan changed" },
      { name: "Failure - No follow up treatment anticipated" },
      {
        name: "Failure - Patient passed away during procedure or due to complications from procedure",
      },
      { name: "Failure - Patient lost to follow up" },
      { name: "Failure - Additional treatment may be necesary" },
      { name: "Failure - Original treatment plan changed" },
      { name: "Did Not Happen - Change in medical stability" },
      { name: "Did Not Happen - Patient did not return for surgery" },
      {
        name: "Did Not Happen - Patient passed away before treatment started",
      },
      { name: "Did Not Happen - Patient decided against surgery" },
      { name: "Did Not Happen - Original treament plan changed" },
      { name: "Did Not Happen - Change in Medical stability" },
      {
        name: "Partial Success - Patient passed away during a multistage surgery",
      },
      { name: "Partial Success - Patient declined further treatment" },
      { name: "Partial Success - Patient lost to follow up" },
      { name: "Partial Success - Change in medical stability" },
      { name: "Partial Success - Original treament plan changed" },
      {
        name: "Partial Success - Additional treatment might be necessary",
      },
      { name: "Other" },
    ],
  },
  {
    id: "treatmentOutcomeOther",
    label: "Treatment Update Summary",
    type: "Textarea",
  },
  {
    id: "feedbackQuote",
    label: "Feedback Quote",
    type: "Textarea",
  },
  {
    id: "postOpPhotos",
    label: "Post Op Photos",
    type: "Photo",
  },
  {
    id: "surgicalScalePhoto",
    label: "Surgical Scale Photo",
    type: "Photo",
  },
];

export default postSurgeryFormFields;
