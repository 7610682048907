import { useEffect, useState } from "react";
import {
  approvePatientFormCase,
  flagPatientFormCase,
  getCase,
  isAdmin,
} from "../services/data/data";
import { Box, Button, Text, Center } from "@chakra-ui/react";
import Loader from "../components/loader";
import patientFormFields from "../services/data/patientFormData";
import Header from "../components/header";
import FormPiece from "../components/formpiece";
import {
  sendPatientFormApproval,
  sendPatientFormFlagged,
} from "../services/email";

const ViewPatientForm = ({ match, location }) => {
  const caseId = match.params.id;

  const [caseData, setCaseData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let data = await getCase(caseId);
      setCaseData(data);
    }
    fetchData();
  }, [caseId]);

  if (!caseData) {
    return <Loader />;
  }

  const editForm = () => {
    window.location = `#/edit_patient_form/${caseData["id"]}`;
  };

  const approveCase = () => {
    // eslint-disable-next-line no-restricted-globals
    let answer = confirm("Approve this case?");
    if (answer) {
      approvePatientFormCase(caseData["id"]);

      const email = sendPatientFormApproval(caseData);

      window.open(email);

      window.location = `#/list`;
    }
  };

  const flagCase = () => {
    let answer = prompt("What is the reason for flagging this case?");

    if (typeof answer == "string" && answer.trim() === "") {
      alert("A reason is needed for flagging a case.");
    }

    if (answer) {
      flagPatientFormCase(answer, caseData["id"]);

      const email = sendPatientFormFlagged(caseData);

      window.open(email);

      window.location = `#/list`;
    }
  };

  const PatientFormButtons = () => {
    let isApproved = caseData["patientFormStatus"] === "Approved";
    let userIsAdmin = isAdmin();

    return (
      <Box width="120px" mx="20px">
        {!isApproved && !userIsAdmin ? (
          <Button mb={1} background="yellow.300" onClick={editForm}>
            Edit Form
          </Button>
        ) : null}

        {userIsAdmin ? (
          <>
            <Button mb={1} background="green.100" onClick={approveCase}>
              Approve Case
            </Button>
            <Button mb={1} background="red.100" onClick={flagCase}>
              Flag Case
            </Button>
          </>
        ) : null}
      </Box>
    );
  };

  return (
    <>
      <Header />
      <Box my={3} mx={"auto"} maxW="960px" display="flex">
        <Box width="800px">
          <Center>
            <Text fontSize="4xl">Patient Form</Text>
          </Center>
          <Center>
            <Text>Status: {caseData["patientFormStatus"]}</Text>
          </Center>
          {caseData["rejectReason"] ? (
            <Center>
              <Box outline={"1px solid"} outlineColor={"red"} px={3}>
                <Text color="red">Reason: {caseData["rejectReason"]}</Text>
              </Box>
            </Center>
          ) : null}

          {patientFormFields.map((f) => (
            <FormPiece key={Math.random()} {...f} value={caseData[f.id]} />
          ))}
        </Box>
        <PatientFormButtons />
      </Box>
    </>
  );
};

export default ViewPatientForm;
