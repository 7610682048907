import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";

const firebaseConfig = {
  apiKey: "AIzaSyDL1_8dlXFoOG1RA8uZENjbDAleP0cUXMw",
  authDomain: "africanmissionhealthcare-e6d6f.firebaseapp.com",
  databaseURL: "https://africanmissionhealthcare-e6d6f.firebaseio.com",
  projectId: "africanmissionhealthcare-e6d6f",
  storageBucket: "africanmissionhealthcare-e6d6f.appspot.com",
  messagingSenderId: "565816901026",
  appId: "1:565816901026:web:fd3d77642ab08746",
};

const fire = firebase.initializeApp(firebaseConfig);

export const db = getFirestore(fire);

export default fire;
