import { useEffect } from "react";
import { logout } from "../services/data/data";
import { Redirect } from "react-router-dom";

const Logout = () => {
  useEffect(() => {
    async function logoutFn() {
      const response = await logout();
    }
    logoutFn();
  }, []);

  return <Redirect to="/login" />;
};

export default Logout;
