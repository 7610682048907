export const waiverFields = [
  {
    id: "qa-waiverPatientNameVerify",
    label: "Waiver - Patient Name is Correct",
    type: "text",
  },
  {
    id: "qa-waiverSignaturePresent",
    label: "Waiver - Signature is Present",
    type: "text",
  },
  {
    id: "qa-waiverSignatureDate",
    label: "Waiver - Signature Date is before Surgery",
    type: "text",
  },
  {
    id: "qa-waiverPostOpPhotoIsAfterSurgery",
    label: "Post Op Photo date is after surgery",
    type: "text",
  },
];

export const profileFields = [
  {
    id: "qa-profilePatientPersonalDescription",
    label: "Profile - Patient Personal Description is present",
    type: "text",
  },
  {
    id: "qa-profileImpactOfIllnessPresent",
    label: "Profile - Impact of Illness is present",
    type: "text",
  },
  {
    id: "qa-profilePersonalQuoteAcceptable",
    label: "Profile - Personal Quote is Acceptable",
    type: "text",
  },
];

export const feedbackFields = [
  {
    id: "qa-feedbackConditionMatchesProfile",
    label: "Feedback - Condition / Surgery matches profile",
    type: "text",
  },
  {
    id: "qa-feedbackPersonalQuoteAcceptable",
    label: "Feedback - Personal Quote is Acceptable",
    type: "text",
  },
];

export const qaFields = [
  {
    id: "qa-updatePassed",
    label: "Update Passed",
    type: "text",
  },
  {
    id: "qa-checkedBy",
    label: "Checked By",
    type: "Select",
    options: [
      { name: "Beatrice" },
      { name: "Robert" },
      { name: "Joan" },
      { name: "Edward" },
      { name: "Other" },
    ],
  },
  {
    id: "qa-checkedByOther",
    label: "Checked By (Other)",
    type: "text",
  },
  {
    id: "qa-dateChecked",
    label: "Date Checked",
    type: "date",
  },
  {
    id: "qa-reviewedBy",
    label: "Reviewed By",
    type: "Select",
    options: [
      { name: "Beatrice" },
      { name: "Beth" },
      { name: "Edward" },
      { name: "Joan" },
      { name: "Robert" },
      { name: "Other" },
    ],
  },
  {
    id: "qa-reviewedByOther",
    label: "Reviewed By (Other)",
    type: "text",
  },
  {
    id: "qa-dateReviewed",
    label: "Date Reviewed",
    type: "date",
  },
  {
    id: "qa-notes",
    label: "Notes / Reasons for flags",
    type: "Textarea",
  },
];
