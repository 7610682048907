import fire from "../fire";
import { v4 as uuidv4 } from "uuid";
import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  getDocs,
  addDoc,
  updateDoc,
} from "firebase/firestore";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Get a reference to the storage service, which is used to create references in your storage bucket
const storage = getStorage(fire);

// Get a reference to the db service
const db = getFirestore(fire);

let cases = [];
let casesInitialized = false;

export const login = (email, pass, error, success, callback) => {
  fire
    .auth()
    .signInWithEmailAndPassword(email.trim().toLowerCase(), pass)
    .catch((e) => {
      error(e.message);
    })
    .then((a) => {
      if (a && a.user) {
        success(a.user);
      }
    })
    .finally((a) => {
      callback(a);
    });
};

export const logout = () => {
  casesInitialized = false;
  cases = [];
  fire.auth().signOut();
};

export const getUser = () => {
  return fire.auth().currentUser || null;
};

export const isAdmin = () => {
  return (
    roleDict[fire.auth().currentUser.email] === roles.SAFE_REPRESENTATIVE ||
    false
  );
};

export const authListener = (setUser) => {
  fire.auth().onAuthStateChanged((user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(null);
    }
  });
};

export const getList = async (
  days = 30,
  invalidateCache = false,
  filters = {}
) => {
  let milliseconds = days * 24 * 60 * 60 * 1000;
  if (getUser() == null) {
    console.log("error");
    return [];
  }

  if (invalidateCache) {
    cases = [];
    casesInitialized = false;
  }

  if (casesInitialized) {
    return cases;
  } else {
    cases = [];
    let admin = hospitalDict[getUser().email] == "ALL";

    let hospitalWhere = admin
      ? null // admin users see all hospital data
      : where("hospital", "in", [hospitalDict[getUser().email]]); //otherwise use hopistal data for user's hospital
    let timestampWhere = where("timestamp", ">", new Date() - milliseconds);

    let patientNameWhere = filters?.name
      ? where("patientName", "in", [filters.name]) //if there's a name filter
      : null; // if there's not

    let q;
    if (hospitalWhere) {
      q = query(collection(db, "cases"), hospitalWhere, timestampWhere);
    } else {
      q = query(collection(db, "cases"), timestampWhere);
    }
    if (patientNameWhere) {
      q = query(collection(db, "cases"), patientNameWhere); //if we're searching for name, forget timestamp
    }

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      cases.push({ id: doc.id, ...doc.data() });
    });

    cases = cases.sort((a, b) => b.timestamp - a.timestamp);

    casesInitialized = true;
    return cases;
  }
};

export const getEmail = async () => {
  return fire.auth().currentUser.email;
};

export const getCase = async (caseId) => {
  if (getUser() == null) {
    console.log("error");
    return [];
  }
  let caseData = cases.find((a) => a.id === caseId);

  if (caseData) {
    return caseData;
  }

  throw Error("Need to get data from collection");
};

export const saveCase = async (data) => {
  try {
    if (data.id) {
      const docRef = doc(db, `cases/${data.id}`);
      updateDoc(docRef, {
        ...data,
        timestamp: new Date().getTime(),
        updatedBy: fire.auth().currentUser.email,
      });
    } else {
      let patientFormStatus = data.patientFormStatus || "Open";
      let uuid = uuidv4();
      addDoc(collection(db, "cases"), {
        ...data,
        timestamp: new Date().getTime(),
        key: uuid,
        hospital: getHospital(),
        patientFormStatus: patientFormStatus,
        SAFEID: "",
        createdBy: fire.auth().currentUser.email,
      });
    }
    return data;
  } catch ({ message }) {
    console.log("Save Case Failed:", message);
  }
};

export const submitPatientForm = (id) => {
  const docRef = doc(db, `cases/${id}`);
  updateDoc(docRef, {
    timestamp: new Date().getTime(),
    updatedBy: fire.auth().currentUser.email,
    patientFormStatus: "Submitted",
  });
};

export const flagPatientFormCase = (reason, id) => {
  if (reason) {
    const docRef = doc(db, `cases/${id}`);
    updateDoc(docRef, {
      timestamp: new Date().getTime(),
      updatedBy: fire.auth().currentUser.email,
      rejectReason: reason,
      patientFormStatus: "Rejected",
    });
  }
};

export const approvePatientFormCase = (id) => {
  const docRef = doc(db, `cases/${id}`);
  updateDoc(docRef, {
    timestamp: new Date().getTime(),
    updatedBy: fire.auth().currentUser.email,
    patientFormStatus: "Approved",
    postSurgeryFormStatus: "Open",
    rejectReason: "",
  });
};

export const approvePostSurgeryFormCase = (id) => {
  const docRef = doc(db, `cases/${id}`);
  updateDoc(docRef, {
    timestamp: new Date().getTime(),
    updatedBy: fire.auth().currentUser.email,
    postSurgeryFormStatus: "Approved",
    safeReviewFormStatus: "Open",
    rejectReason: "",
  });
};

export const flagPostSurgeryFormCase = (reason, id) => {
  if (reason) {
    const docRef = doc(db, `cases/${id}`);
    updateDoc(docRef, {
      timestamp: new Date().getTime(),
      updatedBy: fire.auth().currentUser.email,
      rejectReason: reason,
      postSurgeryFormStatus: "Rejected",
    });
  }
};

export const approveSafeReviewFormCase = (id) => {
  const docRef = doc(db, `cases/${id}`);
  updateDoc(docRef, {
    timestamp: new Date().getTime(),
    updatedBy: fire.auth().currentUser.email,
    safeReviewFormStatus: "Approved",
    rejectReason: "",
  });
};

export const flagSafeReviewCase = (reason, id) => {
  if (reason) {
    const docRef = doc(db, `cases/${id}`);
    updateDoc(docRef, {
      timestamp: new Date().getTime(),
      updatedBy: fire.auth().currentUser.email,
      rejectReason: reason,
      safeReviewFormStatus: "Rejected",
    });
  }
};

const getHospital = () => {
  return hospitalDict[fire.auth().currentUser.email] || "NOT FOUND";
};

export const uploadPhotos = async (files) => {
  let refs = [];
  //uploadPhoto(file);
  //checks if files are selected
  if (files.length != 0) {
    //Loops through all the selected files
    for (let i = 0; i < files.length; i++) {
      //create a storage reference
      let storageRef = ref(storage, `photos2/${files[i].name}`);

      await uploadBytes(storageRef, files[i]);

      let dl = await getDownloadURL(storageRef);

      refs.push(dl);
    }
  }
  return refs;
};

// const collectionName = "cases";
// class DataService {
//   constructor() {
//     firebase.initializeApp({
//       apiKey: "AIzaSyDL1_8dlXFoOG1RA8uZENjbDAleP0cUXMw",
//       authDomain: "africanmissionhealthcare-e6d6f.firebaseapp.com",
//       databaseURL: "https://africanmissionhealthcare-e6d6f.firebaseio.com",
//       projectId: "africanmissionhealthcare-e6d6f",
//       storageBucket: "africanmissionhealthcare-e6d6f.appspot.com",
//       messagingSenderId: "565816901026",
//       appId: "1:565816901026:web:fd3d77642ab08746",
//     });
//   }

//   // getCases = async () => {
//   //   let ref = this.collection.orderBy("timestamp", "desc");

//   //   const defaultHospital = await this.getHospital();

//   //   try {
//   //     const cases = await ref.get();
//   //     const data = [];
//   //     cases.forEach(function (doc) {
//   //       if (doc.exists) {
//   //         const item = doc.data() || {};
//   //         if (defaultHospital == "ALL" || item.hospital == defaultHospital)
//   //           data.push({ id: doc.id, ...item });
//   //       }
//   //     });

//   //     return data;
//   //   } catch ({ message }) {
//   //     alert(message);
//   //   }
//   // };

//   // getCase = async (id) => {
//   //   let docRef = this.collection.doc(id);

//   //   try {
//   //     let result = {};
//   //     const doc = await docRef.get().then((doc) => {
//   //       if (doc.exists) {
//   //         const item = doc.data();

//   //         result = {
//   //           id: doc.id,
//   //           ...item,
//   //         };
//   //       }
//   //     });
//   //     return result;
//   //   } catch ({ message }) {
//   //     alert(message);
//   //   }
//   // };

//   // rejectCase = async (id) => {
//   //   let docRef = this.collection.doc(id);
//   //   try {
//   //     docRef.update({
//   //       patientFormStatus: CaseStatusEnum.Rejected,
//   //       updatedBy: this.uid,
//   //       timestamp: this.timestamp,
//   //     });
//   //   } catch ({ message }) {
//   //     alert(message);
//   //   }
//   // };

//   // approveCase = async (id) => {
//   //   let docRef = this.collection.doc(id);
//   //   try {
//   //     docRef.update({
//   //       patientFormStatus: CaseStatusEnum.Approved,
//   //       updatedBy: this.uid,
//   //       timestamp: this.timestamp,
//   //     });
//   //   } catch ({ message }) {
//   //     alert(message);
//   //   }
//   // };

//   // uploadPhotos = async (photoList) => {
//   //   try {
//   //     let retList = [];
//   //     for (const photo of photoList) {
//   //       const photoUri = await this.uploadSinglePhoto(photo);
//   //       retList.push(photoUri);
//   //     }
//   //     return retList;
//   //   } catch ({ message }) {
//   //     console.log(message);
//   //   }
//   // };

//   // uploadSinglePhoto = async (imageUri) => {
//   //   if (imageUri.indexOf("file") === 0) {
//   //     //it's a local picture
//   //     //upload it, and replace the ref with file.
//   //     const {
//   //       uri: reducedImage,
//   //       width,
//   //       height,
//   //     } = await shrinkImageAsync(imageUri);
//   //     const remoteUri = await this.uploadPhotoAsync(reducedImage);

//   //     return remoteUri;
//   //   } else {
//   //     return imageUri; //it's already a remote uri
//   //   }
//   // };

//   // // Upload Data
//   // uploadPhotoAsync = async (uri) => {
//   //   const path = `photos/${this.uid}/${uuid.v4()}.jpg`;
//   //   return uploadPhoto(uri, path);
//   // };

//   // deleteCase = async (caseId) => {
//   //   let docRef = this.collection.doc(caseId);
//   //   docRef.delete();
//   // };

//   // generateSAFEID = async () => {
//   //   const hospital = await this.getHospital();
//   //   const caseCount = Date.now(); //  await this.getCaseCount();
//   //   return `${hospital}_${caseCount}`;
//   // };

//   // getHospital = () => {
//   //   return hospitalDict[this.email] || "NOT FOUND";

//   //   // let ref = await this.usersCollection
//   //   //   .where("email", "==", email)
//   //   //   .get()
//   //   //   .then(snapshot => {
//   //   //     if (snapshot.empty) {
//   //   //       console.log("No matching documents.");
//   //   //       return "";
//   //   //     }

//   //   //     for (doc in snapshot) {
//   //   //       return doc.data().hospital;
//   //   //     }
//   //   //   });

//   //   // console.log("alan", ref);
//   // };

//   // getCaseCount = async () => {
//   //   const cases = await this.collection.where(
//   //     "hospital",
//   //     "==",
//   //     await this.getHospital()
//   //   );
//   //   return cases.length;
//   // };

//   // saveCase = async (data, caseId) => {
//   //   try {
//   //     //if it already has an id, update it, otherwise save it.
//   //     let photolist = [];
//   //     if (data.patientPhotos && data.patientPhotos.length > 0) {
//   //       photolist = await this.uploadPhotos(data.patientPhotos);
//   //     }

//   //     let patientConsent = null;
//   //     if (data.patientConsent && data.patientConsent.length > 0) {
//   //       patientConsent = await this.uploadPhotos(data.patientConsent);
//   //     }

//   //     const dataToSave = {
//   //       ...data,
//   //       patientConsent: patientConsent,
//   //       patientPhotos: photolist,
//   //       timestamp: this.timestamp,
//   //     };

//   //     if (data.id) {
//   //       let docRef = this.collection.doc(caseId);
//   //       docRef.update({
//   //         ...dataToSave,
//   //         updatedBy: this.email,
//   //       });
//   //     } else {
//   //       this.collection.add({
//   //         ...dataToSave,
//   //         key: uuid.v4(),
//   //         hospital: await this.getHospital(),
//   //         SAFEID: await this.generateSAFEID(),
//   //         status: CaseStatusEnum.Open,
//   //         createdBy: this.email,
//   //       });
//   //     }
//   //     return dataToSave;
//   //   } catch ({ message }) {
//   //     console.log("Save Case Failed:", message);
//   //   }
//   // };

//   // isAdmin = () => {
//   //   return roleDict[this.email] === roles.SAFE_REPRESENTATIVE;
//   // };

//   // get usersCollection() {
//   //   return firebase.firestore().collection("users");
//   // }

//   // // Helpers
//   // get collection() {
//   //   return firebase.firestore().collection("cases");
//   // }

//   // get uid() {
//   //   return (firebase.auth().currentUser || {}).uid;
//   // }

//   // get email() {
//   //   return (firebase.auth().currentUser || {}).email;
//   // }

//   // get timestamp() {
//   //   return Date.now();
//   // }

//   // get role() {
//   //   return roleDict[this.email] || "";
//   // }
// }

export const roles = {
  SAFE_REPRESENTATIVE: "safe_representative",
  HOSPITAL_REPRESENTATIVE: "hospital_representative",
};

export const hospitalList = [
  "AIC Kapsowar hospital",
  "ALMC/ Plaster house ",
  "Bethany Kids- Ethiopia",
  "Bethany Kids- Kijabe",
  "Cure International",
  "Kijabe Hospital",
  "Maua methodist hospital",
  "Nazareth Hospital",
  "Nyakibale Hospital",
  "Partners in Hope Malawi",
  "Rushoroza hospital",
  "Rwibaale Health Center IV",
];

export const hospitalDict = {
  "safeprogram@pihmalawi.com": "Partners in Hope Malawi",
  "saferushoroza1@gmail.com": "Rushoroza hospital",
  "safenyakibale@gmail.com": "Nyakibale Hospital",
  "nyakiwatsi@gmail.com": "Nyakibale Hospital",
  "nprep@kijabehospital.org": "Kijabe Hospital",
  "safe@bethanykids.org": "Bethany Kids- Kijabe",
  "watsicure@gmail.com": "Cure International",
  "watsinazareth@gmail.com": "Nazareth Hospital",
  "safemaua@gmail.com": "Maua methodist hospital",
  "watsi.kapsowar@gmail.com": "AIC Kapsowar hospital",
  "sponsorship@theplasterhouse.org": "ALMC/ Plaster house ",
  "watsibkmcm@gmail.com": "Bethany Kids- Ethiopia",
  "saferushoroza@gmail.com": "Rushoroza hospital",
  "alangfiles+hospitalrep@gmail.com": "Kijabe Hospital",
  "rwibaaleissafe@gmail.com": "Rwibaale Health Center IV",
  "sponsor@africanmissionhealthcare.org": "ALL",
  "alangfiles+safeadmin@gmail.com": "ALL",
  "jon.fielder@africanmissionhealthcare.org": "ALL",
  "muingo.mutisya@africanmissionhealthcare.org": "ALL",
};

const roleDict = {
  "rwibaaleissafe@gmail.com": roles.HOSPITAL_REPRESENTATIVE,
  "nyakiwatsi@gmail.com": roles.HOSPITAL_REPRESENTATIVE,
  "nprep@kijabehospital.org": roles.HOSPITAL_REPRESENTATIVE,
  "safe@bethanykids.org": roles.HOSPITAL_REPRESENTATIVE,
  "watsicure@gmail.com": roles.HOSPITAL_REPRESENTATIVE,
  "watsinazareth@gmail.com": roles.HOSPITAL_REPRESENTATIVE,
  "safemaua@gmail.com": roles.HOSPITAL_REPRESENTATIVE,
  "watsi.kapsowar@gmail.com": roles.HOSPITAL_REPRESENTATIVE,
  "sponsorship@theplasterhouse.org": roles.HOSPITAL_REPRESENTATIVE,
  "watsibkmcm@gmail.com": roles.HOSPITAL_REPRESENTATIVE,
  "saferushoroza@gmail.com": roles.HOSPITAL_REPRESENTATIVE,
  "alangfiles+hospitalrep@gmail.com": roles.HOSPITAL_REPRESENTATIVE,

  "sponsor@africanmissionhealthcare.org": roles.SAFE_REPRESENTATIVE,
  "alangfiles+safeadmin@gmail.com": roles.SAFE_REPRESENTATIVE,
  "jon.fielder@africanmissionhealthcare.org": roles.SAFE_REPRESENTATIVE,
  "muingo.mutisya@africanmissionhealthcare.org": roles.SAFE_REPRESENTATIVE,
};

// DataService.shared = new DataService();
// export default DataService;
