import { Text, Flex, Image, HStack, Button, Link, Box } from "@chakra-ui/react";
import logo from "../assets/icon.png";
import { getUser } from "../services/data/data";

const Header = () => {
  let user = getUser();

  return (
    <Flex justifyContent={"space-between"} alignItems={"center"}>
      <Box m={2}>
        <Image src={logo} w={"80px"} />
      </Box>

      <Box m={2}>
        <Button onClick={() => (window.location = `#/list`)}>List Cases</Button>
        <Button onClick={() => (window.location = `#/new_patient_form`)} ml={2}>
          Add new case
        </Button>
      </Box>

      <Box m={2}>
        <HStack spacing="24px">
          <Text fontWeight="bold">{user.email}</Text>
          <Button as={Link} href={`#/logout`}>
            Logout
          </Button>
        </HStack>
      </Box>
    </Flex>
  );
};

export default Header;
