import { useEffect, useState } from "react";
import {
  approveSafeReviewFormCase,
  flagSafeReviewCase,
  getCase,
  isAdmin,
} from "../services/data/data";
import {
  waiverFields,
  profileFields,
  feedbackFields,
  qaFields,
} from "../services/data/safeReviewFormData";
import { Box, Button, Text, Center, Divider } from "@chakra-ui/react";
import Loader from "../components/loader";
import Header from "../components/header";
import FormPiece from "../components/formpiece";
import { sendSAFEReviewFlagged } from "../services/email";

const ViewSafeReviewForm = ({ match, location }) => {
  const caseId = match.params.id;

  const [caseData, setCaseData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let data = await getCase(caseId);
      setCaseData(data);
    }
    fetchData();
  }, [caseId]);

  if (!caseData) {
    return <Loader />;
  }

  const editForm = () => {
    window.location = `#/edit_safe_review_form/${caseData["id"]}`;
  };

  const approveCase = () => {
    let answer = window.confirm("Approve this case?");
    if (answer) {
      approveSafeReviewFormCase(caseData["id"]);
      window.location = `#/list`;
    }
  };

  const flagCase = () => {
    let answer = window.prompt("What is the reason for flagging this case?");

    if (typeof answer == "string" && answer.trim() === "") {
      alert("A reason is needed for flagging a case.");
    }

    if (answer) {
      flagSafeReviewCase(answer, caseData["id"]);
      const email = sendSAFEReviewFlagged(caseData);

      window.open(email);
      window.location = `#/list`;
    }
  };

  const OtherFormButtons = () => {
    let isApproved = caseData["safeReviewFormStatus"] === "Approved";
    let userIsAdmin = isAdmin();

    return (
      <Box width="120px" mx="20px">
        {!isApproved ? (
          <Button mb={1} background="yellow.300" onClick={editForm}>
            Edit Form
          </Button>
        ) : null}

        {userIsAdmin ? (
          <>
            <Button mb={1} background="green.100" onClick={approveCase}>
              Approve Case
            </Button>
            <Button mb={1} background="red.100" onClick={flagCase}>
              Flag Case
            </Button>
          </>
        ) : null}
      </Box>
    );
  };

  return (
    <>
      <Header />
      <Box my={3} mx={"auto"} maxW="960px" display="flex">
        <Box width="800px">
          <Center>
            <Text fontSize="4xl">SAFE Review Form</Text>
          </Center>
          <Center>
            <Text>Status: {caseData["safeReviewFormStatus"]}</Text>
          </Center>
          {caseData["rejectReason"] ? (
            <Center>
              <Box outline={"1px solid"} outlineColor={"red"} px={3}>
                <Text color="red">Reason: {caseData["rejectReason"]}</Text>
              </Box>
            </Center>
          ) : null}

          <Center>
            <Text fontSize="2xl">Waiver Fields</Text>
          </Center>
          {waiverFields.map((f) => (
            <FormPiece {...f} value={caseData[f.id]} />
          ))}
          <Divider />
          <Center>
            <Text fontSize="2xl">Profile Fields</Text>
          </Center>
          {profileFields.map((f) => (
            <FormPiece {...f} value={caseData[f.id]} />
          ))}
          <Divider />
          <Center>
            <Text fontSize="2xl">Feedback Fields</Text>
          </Center>
          {feedbackFields.map((f) => (
            <FormPiece {...f} value={caseData[f.id]} />
          ))}
          <Divider />
          <Center>
            <Text fontSize="2xl">QA Fields</Text>
          </Center>
          {qaFields.map((f) => (
            <FormPiece {...f} value={caseData[f.id]} />
          ))}
          <Divider />
        </Box>
        <OtherFormButtons />
      </Box>
    </>
  );
};

export default ViewSafeReviewForm;
